"use client"

import { useState, useEffect, useRef } from "react"
import { MessageCircle, Send } from "lucide-react"

interface Message {
  content: string
  type: "bot" | "user"
  id: number
}

const steps = [
  {
    content: `You are going to learn about the below 3 terms today:

1. What is a bond?
2. What is a secured bond?
3. What is a Gold Collateral Bond?`,
    button: "I'm eager to learn",
  },
  {
    content: `Let's start with bonds. In a simple use case:

Let's say your friend asks you for money and promises to return it with interest along with the principal.

A bond works similarly. You can lend your money to the government, banks, NBFCs, or corporates, and they pay back your principal with interest in a legal and regulated way.`,
    button: "Why does one need to invest in bonds?",
  },
  {
    content: `Bonds are a low-risk, fixed-income product offering higher returns than fixed deposits.


But let's discuss the risks first.`,
    button: "Let me know about risks first",
  },
  {
    content: `In the case of your friend, the worst-case scenario is:

1. They might cheat you and run away with your money
2. They might genuinely go bankrupt and be unable to pay you back`,
    button: "Yes",
  },
  {
    content: `In bonds, it's impossible for issuers to run away with your money. Bonds are highly structured and regulated financial products, and each listed bond is under SEBI's surveillance.

However, the actual risk is default. For example, if you buy a bond from Company X and its business defaults, the company may not pay back your principal.`,
    button: "I'm scared. Is there any way to protect my capital?",
  },
  {
    content: `Yes! Enter the concept of secured bonds.

In your friend's example, instead of trusting them blindly, you can take assets of the same or higher value as collateral. If they don't pay you back, you have the right to liquidate their assets.

Secured bonds work similarly, offering added security against defaults.`,
    button: "Yes, that seems secure",
  },
  {
    content: `Some companies/Banks issue bonds by putting equivalent or higher value assets as collateral. These are called Secured Bonds.

Would you like to learn about an even more secure option called Senior Secured Bonds?`,
    button: "Yes, tell me about Senior Secured Bonds",
  },
  {
    content: `Senior Secured Bonds offer the highest level of protection.

If a company defaults, Senior Secured Bond holders get first priority in repayment. This means you have the first claim on the company's pledged assets.`,
    button: "That sounds promising",
  },
  {
    content: `Now, let's talk about Gold Collateral Bonds.

These are secured bonds where gold acts as the collateral. The issuer pledges gold as security, ensuring high protection for your investment.`,
    button: "If it's so good, why isn't everyone investing?",
  },
  {
    content: `Great question! India's financial literacy rate is still developing. These bonds were traditionally popular among High Net Worth Individuals (HNIs) and institutional investors, with minimum investments of ₹10 lakhs.

Recently, SEBI has made these bonds more accessible with lower investment amounts (₹1 lakh, ₹10,000, or even ₹1,000) to encourage retail investor participation.`,
    button: "How can I invest in these bonds?",
  },
  
  {
    content: `Now, let me introduce you to our offering: Gold Collateral Senior Secured Bonds.

We source and list these Gold Senior Security bonds, 

Mostly these bonds are issued by NBFCs and are backed by physical gold as collateral. Here's what makes them special:

1. RBI Regulation: NBFCs are regulated by RBI, ensuring strict oversight
2. Gold Collateral: Physical gold worth 110% of the bond value is pledged
3. Quick Liquidation: In case of default, gold can be easily sold to recover your investment`,
    button: "Super cool",
  },
  
  {
    content: `We're launching our first Gold Collateral Senior Secured Bond next week. Here's how you can invest:

1. You'll need a Demat account as these are listed bonds
2. We'll provide complete bond details
3. Once interested, we'll block the bond allocation in your name
4. Transfer the investment amount directly to the RBI account through your netbanking (Indian Clearing Corporation)
5. The bond will be credited to your Demat account

Key Details of Our Upcoming Bond:

• Tenure: 12 Months
• Returns (YTM): 13%
• Type: Senior Secured Bond
• Collateral: 110% of issue size in physical gold
• Monthly interest payout
• Principal returned at maturity`,
    
  },
]

const formatContent = (content: string) => {
  return content
    .split("\n\n")
    .map((paragraph) => paragraph.trim())
    .filter(Boolean)
    .map((paragraph) => {
      // Format numbered lists with proper indentation and styling
      paragraph = paragraph.replace(
        /^\d+\.\s+(.*)/gm,
        (match) => `<div class="flex items-start gap-3 mb-2">
          <span class="text-blue-400 font-semibold min-w-[1.25rem] flex-shrink-0">${match.match(/^\d+\./)[0]}</span>
          <span>${match.replace(/^\d+\.\s+/, "")}</span>
        </div>`,
      )

      // Format bullet points with proper indentation and styling
      paragraph = paragraph.replace(
        /^•\s+(.*)/gm,
        (match) => `<div class="flex items-start gap-3 mb-2">
          <span class="text-blue-400 font-semibold min-w-[1.25rem] flex-shrink-0">•</span>
          <span>${match.replace(/^•\s+/, "")}</span>
        </div>`,
      )

      return `<div class="mb-4 last:mb-0 leading-relaxed whitespace-pre-wrap text-left">${paragraph}</div>`
    })
    .join("")
}

export default function SecuredBondsChatbot() {
  const [currentStep, setCurrentStep] = useState(0)
  const [messages, setMessages] = useState<Message[]>([])
  const [isTyping, setIsTyping] = useState(false)
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    if (currentStep === 0) {
      addBotMessage(steps[0].content)
    }
  }, [currentStep])

  const addBotMessage = async (content: string) => {
    setIsTyping(true)
    await new Promise((resolve) => setTimeout(resolve, 500))
    setMessages((prev) => [...prev, { content, type: "bot", id: Date.now() }])
    setIsTyping(false)
  }

  const handleNext = async () => {
    const userResponse = steps[currentStep].button
    setMessages((prev) => [...prev, { content: userResponse, type: "user", id: Date.now() }])

    if (currentStep < steps.length - 1) {
      const nextStep = currentStep + 1
      setCurrentStep(nextStep)
      await addBotMessage(steps[nextStep].content)
    }
  }

  const handleWhatsAppContact = () => {
    window.open("https://wa.me/918801648801", "_blank")
  }

  return (
    <div className="min-h-screen bg-[#1A1A1A] flex flex-col">
      {/* Header */}
      <header className="bg-[#2D2D2D] border-b border-gray-700 py-4 px-6 sticky top-0 z-10">
        <div className="max-w-4xl mx-auto flex items-center gap-3">
          <MessageCircle className="w-8 h-8 text-blue-400" />
          <h1 className="text-xl font-bold text-white">Senior Secured Bonds</h1>
        </div>
      </header>

      {/* Chat Container */}
      <main className="flex-1 overflow-hidden flex flex-col max-w-4xl w-full mx-auto">
        {/* Messages */}
        <div className="flex-1 overflow-y-auto py-6 space-y-6 px-4">
          {messages.map((message) => (
            <div key={message.id} className={`flex ${message.type === "user" ? "justify-end" : "justify-start"}`}>
              <div
                className={`max-w-[85%] md:max-w-[75%] rounded-2xl px-6 py-4 ${
                  message.type === "user" ? "bg-blue-600 text-white" : "bg-[#2D2D2D] text-gray-100"
                }`}
              >
                <div
                  className="prose prose-lg prose-invert"
                  dangerouslySetInnerHTML={{ __html: formatContent(message.content) }}
                />
              </div>
            </div>
          ))}
          {isTyping && (
            <div className="flex items-center gap-2 text-gray-400">
              <div className="flex gap-1">
                <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "0s" }} />
                <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "0.2s" }} />
                <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "0.4s" }} />
              </div>
              <span className="text-sm">Assistant is typing...</span>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <div className="border-t border-gray-700 p-4 sticky bottom-0 bg-[#1A1A1A]">
          <div className="max-w-4xl mx-auto flex gap-4">
            <button
              onClick={handleNext}
              disabled={currentStep >= steps.length - 1 && !isTyping}
              className="flex-1 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-4 px-6 rounded-xl transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {currentStep < steps.length ? steps[currentStep].button : "Chat Completed"}
            </button>
            {currentStep >= steps.length - 1 && (
              <button
                onClick={handleWhatsAppContact}
                className="bg-green-600 hover:bg-green-700 text-white font-semibold py-4 px-6 rounded-xl transition-colors flex items-center gap-2"
              >
                <Send className="w-5 h-5" />
                Contact on WhatsApp
              </button>
            )}
          </div>
        </div>
      </main>
    </div>
  )
}

