"use client"

import { color } from "framer-motion"
import { useState } from "react"
import { BiFontSize } from "react-icons/bi"

// Icon components (unchanged)
const MessageSquareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
  </svg>
)

const RefreshCwIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 2v6h-6"></path>
    <path d="M3 12a9 9 0 0 1 15-6.7L21 8"></path>
    <path d="M3 22v-6h6"></path>
    <path d="M21 12a9 9 0 0 1-15 6.7L3 16"></path>
  </svg>
)

const TrophyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path>
    <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path>
    <path d="M4 22h16"></path>
    <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path>
    <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path>
    <path d="M9 2v7.5"></path>
    <path d="M15 2v7.5"></path>
    <path d="M12 2v10"></path>
    <path d="M12 12a4 4 0 0 0 4-4V6H8v2a4 4 0 0 0 4 4Z"></path>
  </svg>
)

// Questions array (unchanged)
const questions = [
  {
    id: 1,
    text: "Which HTML tag is used to create an ordered list?",
    options: ["<ul>", "<ol>", "<li>", "<dl>"],
    correctAnswer: 1,
  },
  {
    id: 2,
    text: "What is the correct HTML tag for list items?",
    options: ["<item>", "<list>", "<li>", "<listitem>"],
    correctAnswer: 2,
  },
  {
    id: 3,
    text: "Which type of list should be used when the order of items matters?",
    options: ["Unordered List", "Definition List", "Ordered List", "None of these"],
    correctAnswer: 2,
  },
  {
    id: 4,
    text: "What HTML tag creates a description list?",
    options: ["<dl>", "<dd>", "<dt>", "<desc>"],
    correctAnswer: 0,
  },
  {
    id: 5,
    text: "In a description list, what tag is used for the term?",
    options: ["<dd>", "<dt>", "<dl>", "<term>"],
    correctAnswer: 1,
  },
  {
    id: 6,
    text: "Which attribute can be used to specify the type of marker in an ordered list?",
    options: ["type", "style", "marker", "list-style"],
    correctAnswer: 0,
  },
  {
    id: 7,
    text: "What is the default marker style for unordered lists?",
    options: ["Numbers", "Letters", "Bullet points", "Dashes"],
    correctAnswer: 2,
  },
  {
    id: 8,
    text: "Which tag combination is used to create a nested list?",
    options: ["<ul><ul>", "<li><li>", "<ul><li><ul><li>", "<ol><ul>"],
    correctAnswer: 2,
  },
  {
    id: 9,
    text: "In a description list, what tag is used for the description?",
    options: ["<dt>", "<dl>", "<dd>", "<ds>"],
    correctAnswer: 2,
  },
  {
    id: 10,
    text: "What attribute can be used to start an ordered list from a specific number?",
    options: ["begin", "start", "initial", "number"],
    correctAnswer: 1,
  },
]

export default function HtmlLists() {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [showResults, setShowResults] = useState(false)
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([])
  const [isTyping, setIsTyping] = useState(false)

  const handleAnswer = (optionIndex: number) => {
    const newAnswers = [...selectedAnswers]
    newAnswers[currentQuestion] = optionIndex
    setSelectedAnswers(newAnswers)

    setIsTyping(true)
    setTimeout(() => {
      setIsTyping(false)
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion((prev) => prev + 1)
      } else {
        setShowResults(true)
      }
    }, 1000)
  }

  const calculateScore = () => {
    return selectedAnswers.reduce((score, answer, index) => {
      return score + (answer === questions[index].correctAnswer ? 1 : 0)
    }, 0)
  }

  const resetQuiz = () => {
    setCurrentQuestion(0)
    setSelectedAnswers([])
    setShowResults(false)
  }

  const score = calculateScore()
  const percentage = (score / questions.length) * 100

  if (showResults) {
    return (
      <div className="quiz-container" style={styles.quizContainer}>
        <div className="quiz-card results-card" style={{ ...styles.quizCard, ...styles.resultsCard }}>
          <div className="results-content" style={styles.resultsContent}>
            <div className="trophy-icon" style={styles.trophyIcon}>
              <TrophyIcon />
            </div>
            <h2 className="results-title" style={styles.resultsTitle}>
              Quiz Complete!
            </h2>
            <p className="results-score" style={styles.resultsScore}>
              Your score: {score}/{questions.length}
            </p>
            <div className="progress-bar-container" style={styles.progressBarContainer}>
              <div className="progress-bar" style={{ ...styles.progressBar, width: `${percentage}%` }}></div>
            </div>
            <p className="results-feedback" style={styles.resultsFeedback}>
              {percentage >= 80
                ? "Excellent! You're a HTML Lists master!"
                : percentage >= 60
                  ? "Good job! Keep practicing!"
                  : "You might want to review HTML Lists concepts."}
            </p>
            <button onClick={resetQuiz} className="reset-button" style={styles.resetButton}>
              <RefreshCwIcon />
              <span>Try Again</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="quiz-container" style={styles.quizContainer}>
      <div className="quiz-card" style={styles.quizCard}>
        <div className="quiz-header" style={styles.quizHeader}>
          <MessageSquareIcon />
          <h1 className="quiz-title" style={styles.quizTitle}>
            HTML Lists Quiz
          </h1>
        </div>

        <div className="quiz-progress" style={styles.quizProgress}>
          <div className="quiz-progress-info" style={styles.quizProgressInfo}>
            <span>
              Question {currentQuestion + 1} of {questions.length}
            </span>
            <span>
              Score: {score}/{currentQuestion}
            </span>
          </div>
          <div className="progress-bar-container" style={styles.progressBarContainer}>
            <div
              className="progress-bar"
              style={{ ...styles.progressBar, width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
            ></div>
          </div>
        </div>

        <div className="quiz-question" style={styles.quizQuestion}>
          <p className="question-text" style={styles.questionText}>
            {questions[currentQuestion].text}
          </p>
          <div className="options-container" style={styles.optionsContainer}>
            {questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswer(index)}
                className={`option-button ${selectedAnswers[currentQuestion] === index ? "selected" : ""}`}
                style={{
                  ...styles.optionButton,
                  ...(selectedAnswers[currentQuestion] === index ? styles.selectedOption : {}),
                }}
              >
                {option}
              </button>
            ))}
          </div>
        </div>

        {isTyping && (
          <div className="typing-indicator" style={styles.typingIndicator}>
            <div className="typing-dots" style={styles.typingDots}>
              <span className="dot" style={styles.dot}></span>
              <span className="dot" style={{ ...styles.dot, animationDelay: "0.2s" }}></span>
              <span className="dot" style={{ ...styles.dot, animationDelay: "0.4s" }}></span>
            </div>
            <span className="typing-text" style={styles.typingText}>
              Processing your answer...
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

// Styles object
const styles = {
  quizContainer: {
    minHeight: "100vh",
    backgroundColor: "#f9fafb",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
  },
  quizCard: {
    maxWidth: "28rem",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "0.75rem",
    boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    padding: "2rem",
  },
  quizHeader: {
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
    marginBottom: "1.5rem",
  },
  quizTitle: {
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  quizProgress: {
    marginBottom: "1.5rem",
  },
  quizProgressInfo: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.875rem",
    color: "#6b7280",
    marginBottom: "0.5rem",
  },
  progressBarContainer: {
    width: "100%",
    backgroundColor: "#e5e7eb",
    borderRadius: "9999px",
    height: "0.25rem",
    overflow: "hidden",
  },
  progressBar: {
    backgroundColor: "#2563eb",
    height: "100%",
    borderRadius: "9999px",
    transition: "width 0.3s ease",
  },
  quizQuestion: {
    marginBottom: "1.5rem",
    color:'black',
    fontWidth:'bold'
  },
  questionText: {
    fontSize: "1.125rem",
    marginBottom: "1rem",
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "0.75rem",
  },
  optionButton: {
    width: "100%",
    textAlign: "left" as const,
    padding: "1rem",
    borderRadius: "0.5rem",
    border: "1px solid #e5e7eb",
    backgroundColor: "transparent",
    transition: "all 0.2s ease",
    cursor: "pointer",
    fontSize: "1rem",
  },
  selectedOption: {
    borderColor: "#2563eb",
    backgroundColor: "#eff6ff",
  },
  typingIndicator: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    color: "#6b7280",
  },
  typingDots: {
    display: "flex",
    gap: "0.25rem",
  },
  dot: {
    width: "0.5rem",
    height: "0.5rem",
    backgroundColor: "#9ca3af",
    borderRadius: "50%",
    animation: "bounce 1.4s infinite ease-in-out both",
  },
  typingText: {
    fontSize: "0.875rem",
  },
  resultsCard: {
    textAlign: "center" as const,
    color:'black'
  },
  resultsContent: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  trophyIcon: {
    margin: "0 auto 1rem",
    width: "4rem",
    height: "4rem",
    color: "#eab308",
  },
  resultsTitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
    marginBottom: "1rem",
      color:'black'
  },
  resultsScore: {
    fontSize: "1.125rem",
    marginBottom: "0.5rem",
  },
  resultsFeedback: {
    marginBottom: "1.5rem",
    color: "#6b7280",
  },
  resetButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",
    margin: "0 auto",
    backgroundColor: "#2563eb",
    color: "white",
    padding: "0.5rem 1.5rem",
    borderRadius: "0.5rem",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.2s ease",
  },
}

